.menu-sendero {
    padding: 20px;

    &.open-menu {
        display: block;
        position: fixed;
        width: 100%;
        background-color: #fff;
        z-index: 999;
        height: 100%;
    }

    &.close-menu {
        display: none;
    }

    .link-pop-up {
        font-size: 12px !important;
    }

    &__content {
        h3 {
            color: $colorVerde;
            font-weight: bold !important;
            font-size: 20px;
            margin-top: 10px;
            margin-bottom: 25px;

            @media (max-width: $tamanioMobile) {
                text-align: center;
            }
        }

        .content-login {

            padding: 0 40px 0 20px;
            border-right: solid 2px #cccccc;

            @media (max-width: $tamanioMobile) {
                padding: 0 !important;
                border-right: none !important;
                border-bottom: solid 2px #cccccc !important;
                padding-bottom: 20px !important;
            }
        }

        .content-blog {
            &__title {
                font-size: 13px;
                margin-bottom: 0;
                margin: 5px 0 5px 0 !important;
            }

            &__excerpt {
                font-size: 12px;
                margin: 0;
            }
        }

        .pagos-en-linea {
            .back-green {
                text-align: center !important;
                color: #fff;
                vertical-align: -webkit-baseline-middle;
                font-weight: bold !important;
                margin: 5px 0 !important;
                cursor: pointer;
                display: inline-block;
                padding: 5px 0 !important;
                max-width: 70% !important;
                width: 100%;
                p{
                    padding: 0 !important;
                }
            }
        }
    }
}