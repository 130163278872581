.products-home {
    &__title {
        font-size: 2em;
        font-weight: 700 !important;
        margin-top: 50px;
        margin-bottom: 68px;
        text-align: center;
    }

    &__container {
        .content-servicio-container {
            padding: 0px 7px;
        }
    }
}

.product-item {
    padding: 0px 4px !important;
    margin-bottom: 15px;
    &__container {
        position: relative;
        min-height: 380px;
        border: solid 1px #ddd;
        border-radius: 3px;
        text-align: center;
    }

    &__icon {
        height: 90px;
        margin-top: 60px;
        margin-bottom: 15px;
    }

    &__title {
        margin-bottom: 18px;
        text-transform: uppercase;
        font-size: 1.5em;
    }

    &__info {
        max-width: 75%;
        margin: auto;
        line-height: 20px;
    }

    &__more {
        position: absolute;
        bottom: 30px;
        text-transform: uppercase;
        transform: translateX(-50%);
        border-radius: 3px !important;
        padding: 8px 25px !important;
    }

    &__more:hover {
        background-color: #00A5C8;
        color: white;
        text-decoration: none;
        box-shadow: none !important;
    }
}