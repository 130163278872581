.canal-denuncias {
    p {
        font-size: 0.9em;
        line-height: 1.5em;
        padding-bottom: 20px;
    }
}

.canal-denuncias-form {
    button:hover {
        background-color: $colorAzul !important;
    }
}

.fondo-gris {
    background-color: #FAFAFA;
    padding: 25px 0;
}

.btn-verde {
    background-color: #94C01F;
    color: $colorBlanco !important;
    border-radius: 4px;
    text-transform: uppercase;
    border-radius: 3px !important;
    padding: 8px 25px !important;
}

.canal-denuncia-page {
    & .text-list {
        padding: 0 !important;
        cursor: pointer;
    }

    & .icon-list {
        top: 9px !important;
        position: relative;
        width: 18px !important;
        transform: rotate(180deg);
        transition-duration: 0.5s;
    }

    & .open-list {
        background: none !important;
    }

    & .open-list>.icon-list {
        transform: rotate(0deg);
    }

    & .text-bold {
        font-weight: 700 !important;
        text-transform: capitalize !important;
    }

    line-height: 1.9;

    & .form-control,
    & .custom-file-label {
        color: #627171;
        border-radius: 1px;
        border-color: #CCCCCC;
        font-size: 12px;

        &::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: #627171;
        }

        &:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: #627171;
        }

        &::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #627171;
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #627171;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #627171;
        }

        &::placeholder {
            /* Most modern browsers support this now. */
            color: #627171;
        }
    }

    & .custom-file-label {
        background-color: #CCCCCC;
    }

    & .custom-file-label::after {
        background-color: #627171;
        border-radius: 0px;
        color: white;
        width: 25%;
        content: "Adjuntar" !important;
        text-align: center;
    }

    & .custom-file-input:lang(en)~.custom-file-label::after {
        content: "Adjuntar" !important;
        background-color: #627171;
    }

    & .custom-file-input:lang(es)~.custom-file-label::after {
        content: "Adjuntar" !important;
    }



    &__info-bottom {
        margin: 80px auto;

        ul {
            color: #627171;
            font-weight: 300;
        }

        li {
            list-style-type: none
        }

        li:before {
            content: "-  "
        }
    }
}

img.loaded {
    width: 50px !important;
    margin: 15px 0 0;
}