/*.compra-linea {
    padding-top: 40px;
    padding-bottom: 60px;

    .title-green {
        text-align: center;
        background-color: #95C11F;
        display: inline-block;
        width: 100%;
        padding: 0 0 4px 0;
        color: #fff;
        margin-bottom: 4px;
        font-size: 25px;
        font-weight: 400 !important;

        @media (min-width: $tamanioMobile) {
            font-size: 38px !important;
        }

        strong {
            font-weight: bold;
        }
    }

    .back {
        background-color: $colorAzul;
        padding: 0px 7px 1px;
        border-radius: 20px;
        color: #fff;
        position: absolute;
        font-size: 15px;
        cursor: pointer;
    }

    .box {
        margin: 20px;
        padding: 35px 30px 100px;

        h3 {
            text-align: center;
            font-size: 18px;
            max-width: 300px;
            margin: 0 auto 30px;
            font-weight: 400 !important;
        }

        button {
            max-width: 350px;
            display: block;
            margin: 0 auto 15px;

            @media (min-width: $tamanioMobile) {
                text-transform: uppercase;
                font-weight: bold;
                width: 100%;
                border-radius: 1px !important;
                font-size: 20px;
                padding: 10px !important;
            }
        }
    }
}*/

/*.box-precios {
    .precio-normal {
        .total {
            color: #f00 !important;
            font-size: 12px;
            font-weight: bold !important;
        }

        .valores {
            text-decoration: line-through;
            font-size: 13px;
            color: #f00;

            .precio {
                color: #f00;
            }
        }
    }

    .precio-web {
        margin-top: 10px;

        .total {
            font-weight: bold !important;
        }

        .valores {
            font-weight: bold !important;
            color: $colorAzul;

            .precio {
                font-weight: bold !important;
            }
        }
    }

    .disclaimer {
        font-size: 13px;
    }
}*/

.bg-blue {
    background-color: #00A5C8;
    text-align: center;
    height: 240px;

    .content-text {
        margin-top: 85px;
        vertical-align: middle;

        h2 {
            color: #fff;
            font-weight: bold !important;
            font-size: 28px;
        }

        h4 {
            color: #fff;
            font-weight: bold !important;
        }
    }
}

.bg-green {
    background-color: #94C01F;

    .container {
        max-width: 1000px;

        h2,
        p {
            color: #ffffff;
        }

        h2 {
            font-weight: bold !important;
            text-transform: none;
        }

        p {
            font-weight: 500 !important;
            font-size: 17px;
            line-height: 23px;
        }
    }
}

.paso-a-paso {
    .box-service {
        img {
            border-radius: 10px;
            margin-bottom: 20px;
        }

        .tx-paso {
            font-size: 16px;
            font-weight: 500 !important;
            margin: 0 !important;
            color: #627171 !important;
        }

        .title {
            margin: 0 !important;
            font-weight: 300 !important;
            color: #00A5C8 !important;
            font-size: 20px;
        }

        &__description {
            font-size: 14px;
            line-height: 24px;
        }
    }
}

.box-product {
    border: solid 1px #cccccc;
    margin: 10px 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    img {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        margin-bottom: 10px;
    }

    .info-producto {
        padding: 5px 30px;

        h2 {
            text-align: center;
            font-weight: bold !important;
            font-size: 18px;
            margin: 10px 0;
        }

        .box-precios {
            .precio-web {
                .valores {
                    font-size: 24px;
                    color: #00A5C8 !important;
                    font-weight: bold !important;
                }
            }

            .disclaimer {
                font-size: 11px;
            }
        }

        .content-button {
            margin: 25px 0;

            button {
                padding: 10px 8px !important;
                font-weight: bold;
                width: 100%;
                border-radius: 0 !important;
                cursor: pointer;
                border: none;
                background-color: #00A5C8;

                &:hover {
                    background-color: #94C01F !important;
                }
            }
        }
    }


    .item-image {
        width: 100%;
    }

    .box-detalle {
        background-color: $colorBlanco;

        @media (min-width: 767px) {
            background-color: #FAFAFA !important;
        }

        @media (max-width: 767px) {
            min-height: auto;
        }

        &__icon_detalle {

            padding: 6px 5px;

            ul {
                padding: 0;
                margin: 0;

                li {
                    list-style: none;
                    font-size: 15px;
                    color: #627171;
                }

                li:before {
                    content: "• ";
                    color: $colorAzul;
                    font-size: 20px;
                    padding-right: 5px;
                }
            }
        }
    }
}

.seleccione-plan {
    // background-color: $colorAzul;
    background-color: #fff !important;
    padding: 10px;
    // border-radius: 5px;
    border: none;
   

    @media (min-width: $tamanioMobile) {
        text-align: center;
        border-radius: 2px;

        h2,
        p {
            display: inline-block !important;
            font-size: 28px !important;
            text-align: center !important;
        }

        p {
            margin-left: 3px !important;
        }
    }

    h2 {
        // color: #fff !important;
        color: #00A5C8 !important;
        font-size: 18px;
        margin: 0;
        font-weight: 700 !important;
        text-transform: initial;
    }

    p {
        color: #fff;
        margin: 0;
    }
}

.btn-pink {
    a {
        background-color: #ff0280;
        text-align: center;
        color: #fff;
        vertical-align: -webkit-baseline-middle;
        font-weight: bold !important;
        margin: 5px 0;
        padding: 10px;
        cursor: pointer;
        display: inline-block;
        padding: 5px 0 !important;
        max-width: 70% !important;
        width: 100%;
    }
}

.content-footer-success {
    margin-top: 15px;

    .fondo-green {
        background-color: #95C11F;
        padding: 10px 350px;

        @media (max-width: $tamanioMobile) {
            padding: 10px 20px;
        }

        p {
            color: #fff;
            font-size: 16px;
        }

        .funeraria {
            margin-top: 15px;
            margin-bottom: 10px;

            a {
                color: #fff;
                text-decoration: underline;
            }
        }
    }
}

.acepta-condicion {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: #95C11F;
    }
}

.btn-blue {
    background-color: #00A5C8;
    color: #fff;
    width: 100%;
    border-radius: 0;
    height: 55px;
    line-height: 20px;

    &:hover {
        background-color: #94C01F;
        color: #fff;
    }
}

.checkout {
    .box__ {
        border-radius: 15px;
        padding: 5px 10px;
        color: #fff;

        &.blue {
            background-color: #00A5C8;
        }

        &.green {
            background-color: #94C01F;
        }
    }

    .form-control {
        border: solid 1px #000;
        border-radius: 8px;
    }

    h2 {
        font-size: 18px;
        font-weight: bold !important;
    }

    .tx-introduccion {
        font-weight: 500 !important;
    }

    label {
        color: #627171;
    }

    &__box {
        border: solid 1px #ccc;
        border-radius: 10px;
        padding: 15px 25px;

        p {
            font-size: 16px;
            max-width: 260px;
        }

        .content-button {
            .button {
                width: 100%;
                margin-bottom: -33px;
                border-radius: 0 !important;
                left: 0;
            }
        }
    }

    .box-resumen {
        &__precio {
            font-size: 22px;
            font-weight: 600 !important;
            color: #00A5C8;

        }

        .disclaimer {
            p {
                font-size: 12px !important;
                max-width: 100%;
            }
        }
    }
}

.product {
    h2 {
        font-size: 18px;
        font-weight: bold !important;
    }

    .box-precios {
        .precio-web {
            .valores {
                font-size: 22px;
                color: #00A5C8 !important;
                font-weight: bold !important;
            }

        }

        .disclaimer {
            font-size: 12px !important;
        }
    }

    .box-acciones {
        i {
            font-size: 36px;
            text-align: center;
        }
    }
}

.content-button {

    .btn-verde,
    .btn-compra {
        width: 100%;
        display: block;
        border-radius: 0 !important;
    }

    .btn-compra {
        background-color: #00A5C8;
        color: #FFFFFF !important;
        border-radius: 3px !important;
        padding: 8px 25px !important;
        height: 45px;
        line-height: 30px;
        border-radius: 0 !important;
        letter-spacing: 1px;

        &:hover {
            background-color: #94C01F;
        }
    }
}

.tx-blue {
    color: #00A5C8;
    font-weight: 600 !important;
}

.table-services {
    margin-top: 10px;

    td {
        color: #000 !important;
        text-align: left;
        padding: 2px 10px;
        border: none;
    }

    tr {
        background-color: #e9f1f5;
    }

    tr:nth-of-type(odd) {
        background-color: #d0e3ea !important;
    }
}