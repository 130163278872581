.mi-sendero-home{
    min-height: auto !important;
    height: auto !important;
}
.home-mobile {
    .content {
        cursor: pointer;

        p {
            color: #fff;
        }

        .pink {
            background-color: #ff0280;
        }

        .blue {
            background-color: #00a5c8;
        }

        .green {
            background-color: #95c11f;
        }

        .gray {
            background-color: #fafafa;
        }

        .icon-xs {
            .icon {
                height: 45px;
            }
        }

        .icon-lg {
            .icon {
                height: 60px;
            }
        }

        .lineal {
            padding: 20px 10px;
            text-align: center;
            margin: 9px 0 !important;
            line-height: 22px;
            vertical-align: middle;

            p {
                display: inline;
                font-size: 20px;
            }

            &.icon-xs {
                .icon {
                    height: 25px !important;
                }
            }
        }

        .contenedor {
            padding: 25px 10px 20px;
            text-align: center;
            margin: 9px 0 !important;
            line-height: 25px;
            vertical-align: middle;
            min-height: 150px;

            .icon {
                margin-bottom: 10px;
            }

            &.gray {
                p {
                    color: #627171;
                }
            }
        }

        .home-contactenos-mobile {
            font-size: 16px;
            margin: 0 !important;
            text-transform: uppercase;
            &.top {
                margin-top: 25px !important;
            }

        }

        .home-text-mobile {
            font-size: 17px;
            font-weight: bold !important;
            margin: 0 !important;
            text-transform: uppercase;

            &.top {
                margin-top: 25px !important;
            }
        }
    }
}