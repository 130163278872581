.home-featured3{
    margin: auto;
    margin-top: 50px;
    margin-bottom: 100px;
    
    .home-featured3-container{
        
        .home-featured3-item{
            text-align: center;
            margin: auto;
        }
        .home-featured3-item-container {
            border-radius: 4px;
            border: none;
            padding: 0 !important;
            margin: 0px 4px;
            height: 310px;

            .img-servicio{
                height: 120px;
                margin-top: 50px;
                margin-bottom: 4px;
            }

            .text-title{
                font-size: 1.3em;
                text-transform: uppercase;                
                padding: 20px 30px;
            }   

            .more-info{
                position: absolute;
                bottom: 30px;
                margin: auto;
                bottom: 30px;
                text-transform: uppercase;
                transform: translateX(-50%);
                border-radius: 3px !important;
                padding: 8px 25px !important;
            }
        }
    }
}

.back-green{
    background-color: #95C11F;
    p{
        color: #fff;
        vertical-align: -webkit-baseline-middle;
    }
    .button{
        background-color: #fff;
        color: #95C11F;
        &:hover{
            background-color: #95C11F !important;
            color: #fff !important;
            border:solid 1px #fff;
        }
    }
}
.back-blue{
    background-color: #00A5C8;
    p{
        color: #fff;
        vertical-align: -webkit-baseline-middle;
    }
    .button{
        background-color: #fff;
        color: #00A5C8;
        &:hover{
            background-color: #00A5C8 !important;
            color: #fff !important;
            border:solid 1px #fff;
        }
    }

}
.back-gris{
    background-color: #627171;
    p{
        color: #fff;
        vertical-align: -webkit-baseline-middle;
    }
    .button{
        background-color: #fff;
        color: #627171;
        &:hover{
            background-color: #627171 !important;
            color: #fff !important;
            border:solid 1px #fff;
        }
    }
}
