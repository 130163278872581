.gallery-parque {
    margin: 30px 0;

    img {
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }

    h2 {
        margin: 40px 0;
    }

    .slick-slide {
        img {
            display: block;
            width: 100% !important;
            margin: 10px 0;
            padding: 0 8px;
        }
    }
}