
.rt-td {
    text-align: center;
}
.admin-transactions-list{
	padding-bottom: 55px;
    border-bottom: dotted 2px $color-borde;
	margin-bottom: 50px;
	.title-lists {
		margin-bottom: 15px;
		font-size: 16px;
		font-weight: bold !important;
	}
	.ReactTable{
		.rt-thead.-header {
			box-shadow: none;
		}
		.rt-thead.-filters .rt-th {
			display: none;
		}
		.rt-th{
			background-color: $back-blue;
			color: $color-white;
			padding: 15px 0 !important;
		}
	}
}