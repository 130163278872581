.home-featured {
    margin-top: 120px;
    margin-bottom: 40px;
    padding: 0;

    .feature-item {
        padding: 0px 7px;

        &.pink {
            .feature-item__wrapper {
                background-color: #ff0280;

                .feature-item__title {
                    color: #fff;
                }

                .feature-item__button {
                    background-color: #ff0280;
                    border: solid 1px #fff;

                    &:hover {
                        background-color: #fff;
                        border: #ff0280;
                        color: #ff0280 !important;
                    }
                }
            }
        }

        &__wrapper {
            border: solid 1px #ddd;
            height: 310px;
            border-radius: 3px;
            padding: 40px 10px 20px;
            background-color: #FAFAFA;
            text-align: center;
        }

        &__icon {
            height: 90px !important;
            margin-bottom: 15px;
        }

        &__button {
            background-color: #94C01F;
            padding: 6px 30px;
            color: #fff !important;
            border-radius: 4px;
            text-transform: uppercase;

            position: absolute;
            bottom: 30px;
            text-transform: uppercase;
            transform: translateX(-50%);
            border-radius: 3px !important;
            padding: 8px 25px !important;

        }

        &__button:hover {
            background-color: #00A5C8;
            text-decoration: none;
        }

        &__title {
            font-size: 20px;
            text-transform: uppercase;
            margin-bottom: 20px;
            color: #627171;
        }
    }
}