$color_present: #95C11F;
$color_white: #fff;

.wizard-container {
    .IconItem-Wizard {
        margin-right: 5px;
        height: 45px;
    }
    .btn-finish {
        display: none !important;
    }
    .nav-pills {
        height: 60px;
        line-height: 60px   !important;
            vertical-align: middle;
            label {
                margin-bottom: 0    !important;
            }
            .ItemStepNameResponsive {
                color: $color_present;
            }
    }
    .nav-link {
        padding: 0 !important;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        color: $color_white;
        background-color: $color_present;
        border-bottom: 3px solid $color_white;
        .ItemStepNameResponsive {
            color: $color_white;
        }
        cursor: pointer;
        padding: 0 !important;
        label    {
            opacity: inherit !important;
            cursor: pointer;
            margin-bottom: 0    !important;
            line-height: 60px   !important;
            vertical-align: middle;
        }
    }

    .card-wizard {
        border-color: $color_present        
    }
    .card-header {
        background-color: $color_white;
        padding: 0;
    }
    .steps_wizard {
        .text-danger {
            color: #dc3545 !important;
        }
    
    }
    label {
        opacity: .7;
    }
    .card-footer {
        background-color: $color_white !important;
        border-color: $color_present;
    }
    .btn-previous {
        background-color: $color_white !important;
        color: $color_present !important;
        border-color: $color_present !important;
        box-shadow: none !important;
    }
    .btn-wizard-next {
        background-color: $color_present;
        color: $color_white;
        border-color: $color_present;
        &:active , &:focus , &:focus-within , &:focus-visible  , &:active:focus , &:active:focus-within , &:active:focus-visible , &:hover:focus , &:hover:hover , &:hover:focus-within , &:hover:focus-visible , &:focus:focus , &:focus:focus-within , &:focus:focus-visible {
            background-color: $color_present !important;
            color: $color_white !important;
            border-color: $color_present !important;
        }
        &:hover {
            background-color: $color_white !important;
            color: $color_present !important;
            border-color: $color_present !important;
            box-shadow: none !important;
        }
    }
    

    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default checkbox */
      .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      
      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: $color_white;
        border: 2px $color_present solid;
      }
      
      /* On mouse-over, add a grey background color */
      .container:hover input ~ .checkmark {
        background-color: #ccc;
      }
      
      /* When the checkbox is checked, add a blue background */
      .container input:checked ~ .checkmark {
        background-color: $color_present;
      }
      
      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the checkmark when checked */
      .container input:checked ~ .checkmark:after {
        display: block;
      }
      
      /* Style the checkmark/indicator */
      .container .checkmark:after {
        left: 6px;
        top: 3px;
        width: 5px;
        height: 8px;
        border: solid $color_white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(35deg);
        -ms-transform: rotate(35deg);
        transform: rotate(35deg);
      }
      .cls_accept_terms {
        font-size: 15px;
        margin-left: -10px;
        position: absolute;
        opacity: .85;
      }
}

.img-steps-thanks {
    height: 200px;
}
@media only screen and (max-width: 960px)  {
    
    .wizard-container {
        .IconItem-Wizard {
            margin-right: 5px;
            height: 25px;
        }
        .ItemStepNameResponsive {
            font-size: 14px;
        }
    }
    .img-steps-thanks {
        height: 150px;
    }
}


.container-steps {
    margin-top: 15px;
}

@media only screen and (min-width: 768px)  and (max-width: 992px) {
    .container-steps {
        margin-top: 120px;
    }
}