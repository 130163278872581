.content-pop-up-oficinas {
    .link-ubicacion {
        color: #95C11F !important;
        cursor: pointer;

        &:hover {
            color: #95C11F !important;
        }
    }

    .icon-parque-page {
        width: .9rem;
        height: .9rem;
        margin-right: 10px !important;
    }

    .data-direccion-oficinas {
        margin-bottom: 10px;
        border-bottom: solid 1px #f1f1f1;
        padding-bottom: 20px;
    }
}

.parques-container {
    padding-bottom: 50px;

    .content-parque {
        margin-bottom: 40px;

        h2 {
            text-align: center;
            color: #627171;
            margin: 10px 0;
            text-decoration: none !important;
            font-size: 20px;
        }

        .content-icon {
            margin: 0 auto;
            text-align: center;

            img {
                position: relative !important;
                margin-bottom: -65px !important;
                top: 0;
                text-align: center;
                display: inline-block;
                border: solid 14px #fff !important;
                border-radius: 79px !important;
                width: 130px !important;
                z-index: 99;
            }
        }

        a {
            text-decoration: none !important;

            &:hover {
                color: #00A5C8;
                text-decoration: none;
            }
        }

        .content-info {
            position: relative;

            &:hover {
                width: 100%;
                height: auto;

                .sombra {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #95c11f8f !important;
                }

                h2 {
                    text-decoration: none !important;
                }
            }
        }

    }
}

.content-page-parque {
    .content-title {
        margin: 20px 0 40px;

        h2 {
            margin-bottom: 30px;
        }
    }

    .content-banner-parque {
        margin-bottom: 30px;
        position: relative;

        .content-anexos {
            position: absolute;
            z-index: 99;
            right: 30px;
            top: 140px;

            @media (max-width: 790px) {
                top: 20px;

                .img {
                    width: 30px !important;
                }
            }

            .circle {
                background-color: #0000008a;
                border: solid 1px #fafafa;
                border-radius: 40px;
                display: inline-block;
                padding: 18px 15px;

                @media (max-width: 790px) {
                    padding: 2px 6px 8px;
                }

                img {
                    width: 40px;

                    @media (max-width: 790px) {
                        width: 25px !important;
                    }
                }
            }

            p {
                font-size: 12px;
                margin-bottom: 10px;
                color: #fff;
            }
        }

        .portada {
            min-height: 420px;
            background-color: #cccccc;
            position: relative;
            height: 100%;
            display: block;
            width: 100%;

            @media (max-width: 790px) {
                min-height: auto !important;
            }
        }

        .content-sendero {
            position: relative;

            .content-icon-interno {
                position: absolute;
                z-index: 9;
                width: 100%;
                text-align: center;
                top: -280px;

                @media (max-width: 790px) {
                    top: -170px !important;

                    img {
                        width: 100px !important;
                    }
                }

                h2 {
                    margin-top: 10px;
                    color: #ffffff;
                    font-weight: bold !important;
                }
            }
        }
    }

    .content-info-parque-contacto {
        margin: 35px 0;
        padding: 15px 30px 15px 30px;
        background-color: #FAFAFA;
        border-radius: 4px;
        border: 1px solid #f1f1f1;
        min-height: 105px;

        @media (max-width: 790px) {
            margin: 5px 0 10px;
        }

        &.more-service {
            min-height: 80px !important;

            p {
                font-size: 16px;
            }

            a {
                padding: 0 15px !important;
                margin-top: 10px;
                font-size: 14px;
            }

            img {
                width: 80%;
            }
        }

        &.location {
            img {
                height: 30px !important;
            }
        }

        img {
            min-height: 32px;
            height: auto;
        }

        p {
            font-size: 12px;
        }
    }

    .content-jardin-tematico {

        background-color: #FAFAFA;
        border: solid 1px #BEBEBE;
        margin: 20px 0;
        border-radius: 3px;

        div {
            padding: 10px 0;
            text-align: center;

            p {
                height: 37px !important;
                margin: 10px !important;
                position: relative !important;
                overflow: hidden !important;
                font-size: 12px !important;
            }

            div {
                display: inline-block;
                width: 100%;
                vertical-align: top;
                padding: 0 15px;

                img {
                    width: 90px;
                }

                h2 {
                    font-size: 16px;
                    text-align: left;
                }

                p {
                    font-size: 11px;
                    text-align: left;
                }
            }

            .button {
                text-align: center;
                color: #fff !important;
                border-radius: 2px;
            }
        }
    }
}

.spinner-parque {
    position: absolute;
    background-color: #fff;
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 50px;
}