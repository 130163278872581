.NavBarTop {
    margin: 60px 0 25px 0;
    border-bottom: dashed 2px $color-borde;
    padding-bottom: 18px;
    border-top: solid 1px $color-borde;
    padding-top: 15px;

    .navBarTextTitle {
        font-weight: bold !important;
    }

    .icon-volver {
        width: 20px;
        margin-right: 5px;
        margin-top: -5px;
    }
}

.MiSendero {
    margin-top: 35px;
    cursor: pointer;
}