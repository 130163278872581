$size-icon: 70px;
$size-icon-smaller: 60px;

$adjust: 106px;

.home-map{
    padding-top: 70px;
    padding-bottom: 50px;

    &__title{
        text-align: center;
        font-size: 2em;
        padding-bottom: 0px;
        margin-bottom: -40px;
    }

    .Mapa, .MapaBg{
        position: relative;
        height: 500px;
        background-size: contain;
        background-position: center center;
		background-repeat: no-repeat;
		transition: all 0.25s ease-in;

        .iconMap{
            position: absolute;
            z-index: 10;
            width: $size-icon;

            @media (max-width: 1199px){
                width: $size-icon-smaller;
            }
        }

        /* Top */

        .iconArica{
            top: 23px + $adjust;
            left: 43px;
            @media (max-width: 1199px){
                top: 42px + $adjust;
                left: 36px;
            }
        }

        .iconVillaAlemana {
            top: -10px + $adjust;
            left: 165px;
            @media (max-width: 1199px){
                top: 11px + $adjust;
                left: 135px;
            }
        }

        .iconValparaiso {
            top: 35px + $adjust;
            left: 290px;
            @media (max-width: 1199px){
                top: 52px + $adjust;
                left: 242px;
            }
        }

        .iconMaipu{
            top: 40px + $adjust;
            left: 394px;
            @media (max-width: 1199px){
                top: 56px + $adjust;
                left: 329px;
            }
        }
        .iconRancagua {
            top: 61px + $adjust;
            left: 531px;
            @media (max-width: 1199px){
                top: 74px + $adjust;
                left: 445px;
            }
        }
        .iconTemuco{
            top: 64px + $adjust;
            left: 648px;
            @media (max-width: 1199px){
                top: 76px + $adjust;
                left: 542px;
            }
        }

        /* Bottom */

        .iconIquique {
            bottom: -2px + $adjust;
            left: 79px;
            @media (max-width: 1199px){
                bottom: 22px + $adjust;
                left: 65px;
            }
        }
        .iconQuilpue {
            bottom: 10px + $adjust;
            left: 195px;
            @media (max-width: 1199px){
                bottom: 32px + $adjust;
                left: 163px;
            }
        }
        .iconSanAntonio {
            bottom: 6px + $adjust;
            left: 296px;
            @media (max-width: 1199px){
                bottom: 28px + $adjust;
                left: 248px;
            }
        }
        .iconSanBernardo {
            bottom: -5px + $adjust;
            left: 394px;
            @media (max-width: 1199px){
                bottom: 19px + $adjust;
                left: 330px;
            }
        }
        .iconConcepcion {
            bottom: -35px + $adjust;
            left: 520px;
            @media (max-width: 1199px){
                bottom: -6px + $adjust;
                left: 435px;
            }
        }
    }

    .MapaBg{
        z-index: -1;
    }

    @media (max-width: 991px){
        display: none;
    }
}