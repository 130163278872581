.btn-included-others {
    background-color: white;
    border-color: #00A5C8 !important;
    border-bottom-color: #00A5C8 !important;
    color: #00A5C8;
    width: 100%;
    border-radius: 0;
    height: 55px;
    line-height: 20px;
    margin-bottom: 5px;
}

.row-container_margin {
    margin-left: 0px !important;
    margin-right: 15px !important;
    margin-bottom: 5px !important;
}

.btn-included-others:hover {
    background-color: #00A5C8;
    border-color: #00A5C8 !important;
    border-bottom-color: #00A5C8 !important;
    color: #fff;
    width: 100%;
    border-radius: 0;
    height: 55px;
    line-height: 20px;
    margin-bottom: 5px;
}

.btn-gray {
    background-color: gray !important;
}
.btn-gray:focus {
    box-shadow: 0 0 0 0.2rem gray !important;
}

.container-other-buttons {
    transition: all 0.5s linear;
    position: absolute;
    background-color: white;
    z-index: 99999;
    width: -webkit-fill-available;
    margin-left: -15px;
    padding-left: 15px;
    border-radius: 0px 0px 10px 10px;
}

.hidden {
    display: none;
  }
  
  .visuallyhidden {
    opacity: 0;
  }

  .text-btn-financ {
    font-size: 14px;
  }

  @media screen  and (max-width: 420px)  {
    .text-btn-financ {
        font-size: 10.5px;
        line-height: initial;
      } 
  }