.button-iso {
    height: 30px;
    width: 100px;
    text-align: center;
    cursor: pointer;
    background-color: #00A5C8;
    color: #fff;
    padding: 15px;
    margin-top: 5px;
}
.button-iso:hover {
    text-decoration: underline;
    color: #fff;
    font-weight: 700 !important;
}
.container-button-iso {
    margin-top: 30px;
    margin-bottom: 15px;
}