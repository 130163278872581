.checkout__box {

    border: solid 1px #ccc;
    border-radius: 10px;
    padding: 15px 25px;

    p {
        max-width: 100% !important;
    }
}
