.content-parque-para-todos {
    .parrafo {
        font-size: 21px;
        margin: 0 !important;
    }

    .middle {
        padding: 20% 0;
    }

    .home-featured {
        margin: 50px auto 70px;
    }

    .action {
        cursor: pointer;
        border-bottom: solid 1px;
        display: inline-block;
        font-weight: 500 !important;

        &.orange {
            color: #f39200;
        }

        &.blue {
            color: #23a7cb;
        }

        &.green {
            color: #96bf0c;
        }
    }

    .title-comunidad {
        margin: 30px 0 40px;
    }

    .logo-section {
        .line {
            width: 40px;
            height: 2px;
            background-color: #96bf0c;
            display: inline-block;
            margin-right: 10px;
            vertical-align: middle;
            margin-top: -6px;
        }

        img {
            display: inline-block;
        }
    }

    .title-mantencion {
        margin-bottom: 30px;
    }

    .icon-flotante {
        position: absolute;
        right: -58px;
        top: 180px;
        z-index: 99;

        img {
            width: 90px !important;
        }
    }

    .cuidado-agua {
        p {
            font-size: 18px;
        }

        .circle {
            &.blue {
                background-color: #00a5ca !important;
            }
        }

        .box-image {
            text-align: center;
            padding: 22.7% 50px;
            background-color: $colorAzul;

            p {
                color: #fff;
            }
        }

        .box-list {
            .title {
                background-color: #96c11f;

                p {
                    color: #fff;
                    font-weight: bold !important;
                }
            }

            .content {
                background-color: #F5F5F5;
                padding: 40px 30px 25px;
                min-height: 280px;

                ul {
                    li {
                        margin-bottom: 15px;
                        color: #627171;
                        list-style: none;
                        img{
                            margin-top: -2px;
                            margin-right: 10px;
                            width: 20px;
                        }
                    }
                }
            }
        }
    }
}