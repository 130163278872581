.content-box-grilla {
    border-bottom: dotted 2px $color-borde;
    margin-bottom: 25px;

    .content-total-grilla {
        padding: 20px 0;
        margin-bottom: 15px;
        font-weight: bold;
    }
}

.content-contact-mi-sendero {
    margin: 20px 0;

    div {
        .contacto-contactenos {
            font-size: 14px !important;
            font-weight: 500 !important;
        }

        .contacto-phone {
            img {
                width: 42px !important;
                margin-top: 5px;
                margin-right: 5px;
            }

            div {
                display: inline-block;
                margin-top: 5px;
                vertical-align: bottom;

                a {
                    font-size: 24px !important;
                }
            }
        }
    }
}

/**POPUP***/
.container-popup {
    padding: 7% 1%;
    margin: 0;

    header {
        padding-bottom: 7%;
    }

    .content-logo {
        text-align: center;

        img {
            width: 250px;
        }
    }
}

.btn-popup {
    width: 100%;
}

/**GRILLAS**/
.table {
    tr {
        th {
            padding: 0.4rem;
            text-transform: uppercase;
        }

        color: $color-white;
        font-weight: bold;
        text-align: center;
    }

    .body-tr {
        tr {
            color: $color-gray;
        }
    }

    &#table-gray {
        .head {
            background-color: $back-gray;
        }
    }

    &#table-blue {
        .head {
            background-color: $back-blue;
        }

        .vertical {
            vertical-align: top !important;
        }
    }

    &#table-green {
        .head {
            background-color: $back-green;
        }
    }
}

.content-pagar {
    div {
        display: inline-block;
        vertical-align: top;
    }

    .content-text-pagar {
        margin-top: 10px !important;
        margin-right: 20px;

        h3 {
            font-weight: bold !important;
        }
    }

    .content-pagar-valor {
        div {
            display: block;
        }

        .content-precio {
            background-color: $back-gray-claro;
            padding: 15px;
            margin-bottom: 10px;

            p {
                font-weight: bold !important;
                font-size: 24px;
                color: $color-gray;
            }
        }

        .content-button {
            button {
                width: 100%;
            }
        }
    }
}

/**PREGUNTAS FRECUENTES COLLAPSE**/
.content-collapse-preguntas {
    border-bottom: solid 1px $color-borde;
    border-left: solid 1px $color-borde;
    border-right: solid 1px $color-borde;
    cursor: pointer;

    .text-list {
        &:hover {
            background-color: #95c11f;
        }

        padding: 8px 10px;
        color: $color-white;
        background-color: $back-gray;

        &.open-collapse {
            background-color: $back-green;

            .icon-list {
                transform: rotate(-180deg);
            }
        }

    }

    .collapse-contenido {
        padding: 20px;
    }

    /*.collapse {
		padding: 20px;
   }*/
}

/**PREGUNTAS**/
.container-preguntas {
    margin: 10px;

    &__title {
        border: solid 1px $back-gray;
        padding: 8px 10px;
        font-size: 14px;
        margin: 10px 0;
    }

    p {
        font-size: 12px;
        margin: 0;
    }
}

.volver {
    color: $back-green;
    font-weight: bold;
}

.content-bienvenida {
    p {
        font-weight: bold !important;
    }
}


.admin-transactions-list {
    .grid-blue {
        &.rt-thead {
            &.rt-th {
                background-color: $back-blue;
            }
        }
    }

    .grid-green {
        &.rt-th {
            background-color: $back-green;
        }
    }
}

.m-b {
    margin-bottom: 30px;
}

.link-pop-up {
    cursor: pointer;
}

.text-title-detalle {
    margin: 30px 0 50px !important;

    h2 {
        font-weight: bold !important;
    }
}

.logoSendero-detalle {
    width: 250px;
    margin: 30px 0 10px;
}

.back-detalle {
    background-color: $back-gray-claro;
    padding: 30px;

    table {
        width: 100%;

        tr {
            td {
                font-weight: bold;
                padding: 5px 0;
            }
        }
    }

}

.table-detalle {
    width: 100%;
    margin: 20px 0;

    th {
        text-align: inherit;
        background-color: $back-blue;
        color: #ffffff;
        text-align: center;
        padding: 10px 0;
    }

    td {
        text-align: center;
    }
}

.mis-datos {
    font-size: 22px !important;
    margin-bottom: 10px;
    font-weight: bold;
}

.title-lists {
    font-size: 18px;
    font-weight: bold !important;
}

.modal-mantencion {
    .modal-header {
        padding: 20px 0;
        background-color: #95C11F;

        h5 {
            font-weight: bold !important;
            color: #ffffff;
            margin: 0 auto;
            font-size: 32px;
        }
    }

    .modal-body {
        padding: 30px 35px;
        text-align: center;

        p {
            font-weight: 500 !important;
            font-size: 18px;

        }

        .content-montos {
            padding: 0 5px;
            margin-top: 20px;

            p {
                color: #000000;
                font-size: 14px;
            }

            .verde {
                background-color: $back-green;
            }

            .azul {
                background-color: $back-blue;
            }

            .title {
                border: solid 1px #f1f1f1;
                color: #ffffff;
                padding: 2px 0;
            }

            .price {
                margin-top: 5px;
                padding: 2px 0;
                border: solid 1px #f1f1f1;
                background-color: #DBDBDB;
            }
        }
    }

    .footer-mantencion {
        padding: 0 35px 15px;

        .btn-mantencion {
            width: 100%;
            font-size: 13px;
            padding: 10px 0;
        }

        .padding-button {
            padding: 0 5px 0 6px;
        }
    }

    .ver-byc {
        padding-bottom: 15px;
        color: #9D9D9D;
        font-size: 12px;
        text-decoration: underline;
        cursor: pointer;
    }

    .tyc {
        padding: 30px 35px;
        position: absolute;
        background-color: #fff;
        top: 88px;
        height: 100%;

        h4 {
            font-weight: bold !important;
        }

        p {
            font-size: 14px;
        }
    }
}

.icon-pie {
    transform: rotate(-180deg);
    background-color: #fff;
    padding: 11px 5px;
    border-radius: 30px;
    margin-top: -6px;
}

.popup-mi-sendero {
    z-index: 9999;
}

.fail {
    border: solid 1px #ff0000 !important;
}

.text-collapse-ndoc {
    font-size: 12px;
    text-align: left;
    color: #627171;
    text-decoration: underline;
    border: solid 1px #f1f1f1;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 10px;
}

.btn-mi-sendero {
    background-color: #95C11F;
    margin-top: 35px;
    margin-bottom: 10px;
    width: 100%;

    p {
        color: #fff;
        vertical-align: -webkit-baseline-middle;
        font-weight: bold !important;
        padding: 2px 0;
        cursor: pointer;
    }
}

@media (max-width: 790px) {
    .btn-mi-sendero {
        border-radius: 0 !important;
        display: inline-block;
        padding: 5px 0 !important;
        max-width: 70%;
        &.btn-verde {
            padding: 5px 0 !important;
            border-radius: 0 !important;
        }

        p {
            padding: 0;
        }
    }
}

.toll {
    .tooltip-inner {
        width: 100%;
        max-width: 350px;
        padding: 1.25rem 1rem;
        text-align: center;
        background-color: #8db422 !important;
        border-radius: .25rem;

        p {
            color: #fff;
            font-size: 16px;
        }
    }

    .arrow {
        &::before {
            border-top-color: #86b221 !important;
        }
    }
}

.content-postergacion {
    background-color: #fdfdfd;
    border: solid 1px #f1f1f1;
    padding: 50px 30px;

    p {
        font-size: 20px !important;
        margin-bottom: 10px;
        font-weight: 500 !important;
    }

    .back-postergacion {
        margin-top: 30px;
        padding: 40px 25px;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;

        img {
            display: inline-block;
            width: 50px;
            margin-top: -5px;
            margin-right: 15px;
        }

        h3 {
            display: inline-block;
            color: #fff;
            font-weight: bold !important;
            margin: 0;
            vertical-align: middle;
        }

        button {
            padding: 15px 30px !important;
            font-size: 13px;
            font-weight: 600 !important;
            border: solid 1px;
        }
    }
}