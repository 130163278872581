.image-container-clicked-mb {
    display: flex;
    flex-direction: column;

}
.image-section-click-mb {
    position: absolute;
    overflow: hidden;
    margin-top: 50%;
}
.image-section-click_0-mb {
    position: absolute;
    overflow: hidden;
}
.image-link-goto-mb {
    display: block;
    width: 20%;
    height: 100%;
}