.main { 
    width: 100%;
    border-bottom: solid 1px #f1f1f1;

    .navbar {
        padding: 18px 1rem 12px;

        .navbar-nav {
            margin: 0 auto;
            margin-right: 0;
            .nav-item {
                padding: 0 5px;

                &.espacio {
                    width: 80px; 
                }

                .nav-link {
                    color: rgba(0, 0, 0, .5);
                    font-weight: 700;
                    padding: 0 5px;
                    font-size: 12px;
                    margin-top: 6px;
                    text-transform: uppercase;

                    &.active {
                        color: #00a5c8 !important;
                    }

                    &:hover {
                        color: #00a5c8 !important;
                    }

                    &.items {
                        padding-right: 0;
                        padding-left: 15px;
                    }
                }

                .top-header-contact { 

                    &.verde {
                        .phone {
                            color: $colorVerde;

                            &:hover {
                                color: $colorAzul !important;
                            }
                        }

                        .fa-whatsapp {
                            font-size: 25px;
                            padding-right: 5px;
                            color: #95c11f;
                        }
                    }

                    &.azul {
                        .phone {
                            color: $colorVerde;

                            &:hover {
                                color: $colorAzul !important;
                            }
                        }

                        .img-247 {
                            width: 25px;
                            margin-right: 5px;
                        }
                    }

                    .tel {
                        .canal {
                            margin-bottom: -5px;
                            text-align: left;
                            font-weight: 700 !important;
                            -webkit-transform: translateY(5px);
                            transform: translateY(5px);
                        }

                        .phone {
                            margin-top: -5px;
                            text-align: left;
                            font-weight: 800 !important;
                        }
                    }

                    div {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    p {
                        font-size: 10px;
                    }

                    span {
                        font-size: 11px;
                    }
                }
            }
        }
    }
} 