.content-logo-pop-up {
    margin-bottom: 25px;
}

.content-detalle-pop-up {
    border-top: solid 1px #f1f1f1;
    border-bottom: solid 1px #f1f1f1;
    padding: 2px 0px;
    margin: 5px 0;
}

.content-cotizar {
    & .cotizar-logo {
        margin-bottom: 20px;
    }

    & .cotizar-texto {
        text-align: center;
        margin: 0 10px 10px;
    }
}

.parque-capitalize {
    text-transform: capitalize;
}

img.icon-modal {
    display: inline-block;
    width: 12px;
    margin-top: -2px;
    margin-right: 5px;
}

.text-popup {
    display: inline;
}

@media (min-width: 575px) {
    .muestra-br {
        display: none;
    }
}

.page-gracias {
    .box-page {
        margin-top: 20px;

        .title {
            color: #00A5C8;
            font-weight: bold !important;
            margin: 20px 0 20px;
            text-transform: none;
            font-size: 34px;
        }

        .content {
            font-size: 20px;
        }
    }
}