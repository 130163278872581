.box-top {
    background-color: #95C11F;
    width: 100%;
    display: block;
    color: #ffffff !important;
    padding: 1px 0;
    border: solid 2px #fff;
}

.menu-fixed {
    background-color: #fff;
    width: 100%;
    z-index: 999;

    .mega-menu {
        .content-mm {
            .open_click {
                cursor: pointer;

                .burger {
                    display: inline-table;
                    vertical-align: top;
                    margin-left: 12px;
                    padding-top: 7px;

                    span {
                        background-color: #627171;
                        height: 2px;
                        width: 18px;
                        display: block;
                        margin-bottom: 3px;
                        margin-right: 4px;
                    }
                }

                span {
                    font-size: 13px;
                }
            }

            .showMenu {
                display: inline-flex;
                width: 100%;
                position: fixed;
                background-color: #0000003b;
                height: 100%;
                z-index: 999;
                top: 0;

                .close_click {
                    color: #1aa5c8;
                    font-size: 18px;
                    padding: 20px 30px;
                    cursor: pointer;

                    span {
                        font-size: 20px;
                    }

                    background-color: #fff;
                    max-width: 272px;
                    background-color: #fff;
                    width: 270px;
                    position: fixed;
                }

                &__space_white {
                    background-color: #fff;
                    display: inline-block;
                    min-width: 271px;
                    height: 100%;
                    overflow-y: scroll;
                    padding-top: 70px;

                    .fa-chevron-right {
                        position: relative;
                        float: right;
                        top: 5px;
                        right: 5px;
                    }

                    .item {
                        cursor: pointer;
                        padding: 10px 8px;
                        border-left: solid 3px #fff;



                        &:hover {
                            border-left: solid 3px #1aa5c8;
                            background-color: #edefef;

                            .item__space_white {
                                display: block !important;
                            }
                        }

                        &__space_white {
                            padding-top: 70px;
                            display: none;
                            background-color: #fff;
                            border-left: solid 1px #edefef;
                            min-width: 200px;
                            height: 100%;
                            position: absolute;
                            left: 255px;
                            top: 0;

                            a {
                                display: inline-block;
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .hideMenu {
                display: none;
            }
        }

        &__top {
            background-color: #fff;
            padding-top: 15px;
            border-bottom: solid 1px #f1f1f1;


            @media (max-width: 790px) {
                padding-top: 0;
                /*position: fixed;*/
                width: 100%;
                /*top: 0;*/
                z-index: 999;
                padding: 0;
                border-bottom: solid 1px #f1f1f1;

                .option {
                    width: 25% !important;
                    padding: 5px 1px !important;
                    font-size: 9px !important;
                    font-weight: bold !important;
                    border-radius: 0 !important;
                    text-transform: uppercase;
                }
            }

            .option {
                background-color: #fefefe;
                display: inline-block;
                border-top: solid 1px #f1f1f1;
                border-left: solid 1px #f1f1f1;
                border-right: solid 1px #f1f1f1;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                padding: 5px 5px;
                width: 135px;
                text-align: center;
                font-size: 13px;
                cursor: pointer;
                color: #627171;
                font-weight: 700;

                i {
                    font-size: 15px;
                }
            }
        }
    }
}

.active {
    .option {
        background-color: #00A5C8 !important;
        color: #fff !important;
    }

    p {
        color: #00A5C8 !important;
    }
}

.button-green {
    background-color: #95C11F !important;
    margin: 5px 10px 10px 8px !important;

    p {
        color: #fff !important;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700 !important;
    }

    &:hover {
        border-left: solid 3px #fff;
    }
}

.item {

    &.item-mobile {
        display: block;
        padding: 10px 0;
        margin: 0;
        border-top: 1px solid #f1f1f1;
        text-align: left;
        cursor: pointer;
        text-transform: uppercase;

        p {
            color: #fff;
            font-weight: 500 !important;
            font-size: 14px !important;
        }

        .active {
            p {
                color: #ccc !important;
            }
        }
    }

    &.item-horizon {
        p {
            color: rgba(0, 0, 0, .5);
            font-weight: 700 !important;
            padding: 0 5px;
            font-size: 12px;
            margin-top: 6px;
            text-transform: uppercase !important;
        }
    }
}

.btn-top {
    display: inline-block !important;
    width: 24%;
    text-align: center;
    color: #fff;
    margin: 0 8px;
    font-size: 14px;
    cursor: pointer;
    text-transform: uppercase !important;

    img {
        width: 10%;
        margin: -4px 2px 0;
    }

    &.green {
        background-color: #95C11F;
    }

    &.blue {
        background-color: #00A5C8;
    }

    &.red {
        background-color: #ff7979;
    }
}


.collapse {
    a {
        display: block;
        padding: 3px 0 !important;
        border: none !important;
        border-bottom: solid 1px #f1f1f12e !important;

        p {
            font-size: 14px !important;
        }

        &:before {
            content: none !important;
        }
    }
}

i.fas.fa-phone {
    transform: rotate(90deg);
}