.shared {
    p {
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 500 !important;
    }

    .copy {
        padding: 5px 10px;
        border-radius: 6px;
        border: none;
        height: 38px;
        width: 38px;
    }

    button {
        margin: 0 3px 10px;
        padding: 0;

        svg {
            width: 38px;
            height: auto;
            border-radius: 6px;
        }

        & .wsp {
            rect {
                fill: #95c11f !important;
            }
        }

        & .fb {
            rect {
                fill: #3b5998 !important;
            }
        }
    }
}