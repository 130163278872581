.height_fixed {
    height: 150px !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    display: block;
    width: 100%;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    border: solid 1px #f1f1f1;
    font-weight: 300 !important;
}