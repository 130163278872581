.banner-notice img {
    object-fit: none;
    object-position: top;
}

.content-items-posts {
    padding-bottom: 30px;

    .content-btn {
        margin-top: 25px;
    }
}

.grid-posts {
    .text-recientes {
        font-weight: bold !important;
        margin-bottom: 22px;
        font-size: 16px;
    }

    &.single {
        & .content-blog {
            img {
                width: 100%;
                height: auto;
            }

            iframe {
                width: 100%;
                height: 400px;
                margin: 10px 0 0 0;
            }

            & .posts-title {
                text-align: left !important;
            }
        }
    }

    &.list {
        p {
            margin: 0 !important;
        }
    }

    .content-gallery {
        margin: 10px;

        &__image {
            width: 100%;
        }
    }

    .content-blog {
        text-align: center;
        padding-bottom: 20px;
        margin-bottom: 20px;

        &__date {
            font-size: 12px;
        }

        &__title {
            font-size: 18px;
            font-weight: 600;
            color: #627171;
            margin: 5px 0 -7px 0 !important;
            display: block;
        }

        &.list-blog {
            border-bottom: solid 1px #f1f1f1;
        }

        .posts-title {
            font-weight: bold !important;
            margin: 15px 0 0;
        }

        div {
            text-align: left;
        }

        .img-back-posts {
            width: 100%;
        }

        .img-posts-list {
            width: 100%;
        }

        p {
            margin: 10px 0 5px;
        }

        .link {
            color: #94C01F !important;
        }
    }

}