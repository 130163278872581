.box {
    //box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 10px #0000001a;
    min-height: 240px;

    .btn-blue {
        background-color: #00A5C8;
        color: #fff;
        text-transform: uppercase;
        font-size: 14px;
    }

    .description-price {
        text-align: right;

        &__desde {
            font-size: 14px !important;
        }

        &__amount {
            font-size: 18px !important;
            font-weight: bold !important;
        }

        &__texto {
            font-size: 12px !important;
        }
    }

    .check-green {
        width: 15px;
        margin-top: -2px;
        margin-right: 5px;
    }

    &__title {
        background-color: #D0746A;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        p {
            color: #fff !important;
            text-transform: uppercase;
            padding: 2px;
        }
    }

    &__title_anfora {
        background-color: #627171;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        p {
            color: #fff !important;
            text-transform: uppercase;
            padding: 2px;
        }
    }

    &__content {
        .image{
            /*max-width: 200px !important;*/
            height: auto !important;

        }
        .description {
            font-size: 14px;
            line-height: 18px;
        }

        p {
            color: #627171 !important;
        }
    }
}

.box-service {
    &__description {
        font-size: 14px;
        line-height: 20px;
        min-height: 130px;
    }

    a {
        font-size: 12px;
        color: #95c11f;
    }
}

.box-plan {
    .title {
        font-size: 30px;
        line-height: 32px;

        .green {
            color: #95c11f;
            margin-top: -2px;
            font-weight: bold;
        }

        .blue {
            color: #00A5C8;
            margin-top: -2px;
            font-weight: bold;
        }
    }

    .description {
        min-height: 150px;

        p {
            font-size: 14px;
            margin: 8px 0;
        }
    }
}

.modal-plan {
    .btn {
        color: #fff;
        border-radius: 0;
        width: 50%;

        @media (max-width: 769px) {
            width: 100%;
        }

        &:hover {
            color: #ccc;
        }
    }

    .btn-green {
        background-color: #95c11f;
    }

    .btn-red {
        background-color: #FF7979;
    }
}

.option-plan {
    p {
        font-size: 15px;
        cursor: pointer;
        text-decoration: underline;
        margin-bottom: 6px;
    }
}

.tab-anforas {
    .nav-tabs {
        border: none !important;
    }

    @media (max-width: 769px) {
        .nav-item {
            width: 50%;
            margin: 2px 0;

            .nav-link {
                padding: 5px 17px;
            }
        }
    }

    .nav-link {
        background-color: #627171;
        color: #fff !important;
        margin: 0 10px;
        border: none !important;
        border-radius: 10px !important;
        padding: 5px 30px;
        font-size: 12px;
        text-transform: uppercase;
    }
}