.precio-normal-sug {
    label   {
        margin: 0 !important;
        color: red;
    }
    p  {
        color: red;
        text-decoration: line-through;
    }
    
}
.precio-web-sug {
    label   {
        margin: 0 !important;
    }
    p {
        color: #00A5C8;
        font-weight: 700 !important;
    }
}