.content-search {
    h2 {
        margin-top: 100px;
        font-size: 30px;
    }

    .content-info-parque-contacto {
        padding: 50px 55px 40px;
        border: solid 1px #f7f7f7;
        box-sizing: border-box;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);

        p {
            font-size: 18px;
            margin: 20px 0;
        }

        .btn-busqueda-sepultura {
            margin-top: 51px !important;
            width: 60%;
            margin: 0 auto;
            font-weight: 300 !important;
        }
    }
}